.letterCard {
    background-color: lightgoldenrodyellow;
}

.letterBody {
    overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.letterText {
    white-space: pre-line;
}