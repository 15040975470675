.blockCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.width100 {
    width: 100%;
}

.modalBody {
    max-height: 70vh; /* Adjust as needed to fit your design */
    overflow-y: scroll;
    word-wrap: break-word;
}