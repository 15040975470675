.letterPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lettersGrid {
    width: 100%;
}

.letter {
    height: 200px;
    min-width: 150px;
    transition: box-shadow .2s ease-in-out;
    cursor: pointer;
}

.receivedLetter {
    height: 200px;
    min-width: 150px;
    transition: box-shadow .2s ease-in-out;
}

.letter:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}